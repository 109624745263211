import React, {FC} from 'react';
import {IContactFormData} from 'components/page/realEstate/common/interfaces/IContactFormData';
import {Grid} from '@mui/material';
import {useContactFormComponents} from 'components/page/realEstate/common/hooks/contactForm/useContactFormComponents';

interface IContactFormProps {
    isSending?: boolean;
    onSubmit: (values: IContactFormData) => void;
}

export const ContactForm: FC<IContactFormProps> = ({onSubmit}) => {
    const {
        textAreaField,
        contactInputsMerged,
        agreeCheckbox,
        recaptcha,
        submitButton,
        handleSubmit,
    } = useContactFormComponents(onSubmit);

    return <form onSubmit={handleSubmit}>
        <Grid container rowSpacing={{xs: 2}} columnSpacing={2}>
            <Grid item xs={12} data-test-id="text">
                {textAreaField}
            </Grid>

            <Grid item xs={12} data-test-id="inputs-merged-name-email-phone">
                {contactInputsMerged}
            </Grid>

            <Grid item xs={12} data-test-id="agreeToTerms" my={2}>
                {agreeCheckbox}
            </Grid>

            <Grid item xs={12} data-test-id="recaptcha">
                {recaptcha}
            </Grid>

            <Grid item xs={12} md={4} data-test-id="submit">
                {submitButton}
            </Grid>

        </Grid>
    </form>;
};
