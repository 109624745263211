import {ECurrency} from 'modules/theme/components/listing/cards/common/enums/parameters/price/ECurrency';
import {EPriceUnit} from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/price/EPriceUnit';
import {
    priceUnitsRecord
} from 'modules/theme/components/listing/cards/advertisementCard/constants/parameters/price/priceUnitsRecord';

export const formatPrice = (
    priceValue: number,
    currency: ECurrency = ECurrency.EUR,
    priceUnit: EPriceUnit = EPriceUnit.CURRENCY
): string => {
    const formatter = new Intl.NumberFormat('sk-SK', {
        maximumFractionDigits: 2,
    });

    return `${formatter.format(priceValue)} ${
        priceUnitsRecord(currency)[priceUnit]
    }`;
};
