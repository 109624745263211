import {RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum} from 'modules/api/generated';
import {ESubcategory} from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/category/ESubcategory';

export const DtoSubCategoryEnumToESubcategory: Record<RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum, ESubcategory> = {
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.StudioApartment]: ESubcategory.STUDIO_APARTMENT,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.DoubleStudioApartment]: ESubcategory.DOUBLE_STUDIO_APARTMENT,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.OneRoomApartment]: ESubcategory.ONE_ROOM_APARTMENT,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.TwoRoomApartment]: ESubcategory.TWO_ROOM_APARTMENT,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.ThreeRoomApartment]: ESubcategory.THREE_ROOM_APARTMENT,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.FourRoomApartment]: ESubcategory.FOUR_ROOM_APARTMENT,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.FivePlusRoomApartment]: ESubcategory.FIVE_PLUS_ROOM_APARTMENT,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.HolidayApartment]: ESubcategory.HOLIDAY_APARTMENT,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.Loft]: ESubcategory.LOFT,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.Maisonette]: ESubcategory.MAISONETTE,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.OtherTypeOfApartment]: ESubcategory.OTHER_TYPE_OF_APARTMENT,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.FamilyHouse]: ESubcategory.FAMILY_HOUSE,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.CountryHouse]: ESubcategory.COUNTRY_HOUSE,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.FarmSettlement]: ESubcategory.FARM_SETTLEMENT,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.MobileHouse]: ESubcategory.MOBILE_HOUSE,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.Houseboat]: ESubcategory.HOUSEBOAT,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.OtherObjectForHousing]: ESubcategory.OTHER_OBJECT_FOR_HOUSING,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.CabinAndLogCabin]: ESubcategory.CABIN_AND_LOG_CABIN,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.CottageAndRecreationHouse]: ESubcategory.COTTAGE_AND_RECREATION_HOUSE,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.GardenHut]: ESubcategory.GARDEN_HUT,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.OtherRecreationalObject]: ESubcategory.OTHER_RECREATIONAL_OBJECT,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.LandForFamilyHouse]: ESubcategory.LAND_FOR_FAMILY_HOUSE,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.RecreationalLand]: ESubcategory.RECREATIONAL_LAND,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.LandForHousingConstruction]: ESubcategory.LAND_FOR_HOUSING_CONSTRUCTION,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.LandForCivicAmenities]: ESubcategory.LAND_FOR_CIVIC_AMENITIES,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.CommercialZone]: ESubcategory.COMMERCIAL_ZONE,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.IndustrialZone]: ESubcategory.INDUSTRIAL_ZONE,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.MixedZone]: ESubcategory.MIXED_ZONE,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.Garden]: ESubcategory.GARDEN,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.Orchard]: ESubcategory.ORCHARD,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.VineyardAndHopGarden]: ESubcategory.VINEYARD_AND_HOP_GARDEN,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.MeadowAndPasture]: ESubcategory.MEADOW_AND_PASTURE,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.ArableLand]: ESubcategory.ARABLE_LAND,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.ForestLand]: ESubcategory.FOREST_LAND,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.PondAndLake]: ESubcategory.POND_AND_LAKE,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.LandForAdvertising]: ESubcategory.LAND_FOR_ADVERTISING,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.OtherTypeOfLand]: ESubcategory.OTHER_TYPE_OF_LAND,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.Offices]: ESubcategory.OFFICES,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.BusinessSpaces]: ESubcategory.BUSINESS_SPACES,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.RestaurantSpaces]: ESubcategory.RESTAURANT_SPACES,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.StorageAreas]: ESubcategory.STORAGE_AREAS,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.SpaceForProduction]: ESubcategory.SPACE_FOR_PRODUCTION,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.RepairArea]: ESubcategory.REPAIR_AREA,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.SportsFacilities]: ESubcategory.SPORTS_FACILITIES,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.SpaceForAdvertisement]: ESubcategory.SPACE_FOR_ADVERTISEMENT,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.OtherTypeOfSpace]: ESubcategory.OTHER_TYPE_OF_SPACE,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.ApartmentHouse]: ESubcategory.APARTMENT_HOUSE,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.RentalHouse]: ESubcategory.RENTAL_HOUSE,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.OfficeBuilding]: ESubcategory.OFFICE_BUILDING,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.CommercialObject]: ESubcategory.COMMERCIAL_OBJECT,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.Restaurant]: ESubcategory.RESTAURANT,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.PolyfunctionalBuilding]: ESubcategory.POLYFUNCTIONAL_BUILDING,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.Warehouse]: ESubcategory.WAREHOUSE,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.ManufacturingFacility]: ESubcategory.MANUFACTURING_FACILITY,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.RepairFacility]: ESubcategory.REPAIR_FACILITY,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.ObjectForBreedingAnimals]: ESubcategory.OBJECT_FOR_BREEDING_ANIMALS,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.HotelAndPension]: ESubcategory.HOTEL_AND_PENSION,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.Spa]: ESubcategory.SPA,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.HistoricalObject]: ESubcategory.HISTORICAL_OBJECT,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.CommercialFacility]: ESubcategory.COMMERCIAL_FACILITY,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.SportObject]: ESubcategory.SPORT_OBJECT,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.SmallPowerStation]: ESubcategory.SMALL_POWER_STATION,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.GasStation]: ESubcategory.GAS_STATION,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.MobileCellsAndStands]: ESubcategory.MOBILE_CELLS_AND_STANDS,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.OtherTypeOfObject]: ESubcategory.OTHER_TYPE_OF_OBJECT,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.DetachedGarage]: ESubcategory.DETACHED_GARAGE,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.ConsolidatedGround]: ESubcategory.CONSOLIDATED_GROUND,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.AgriculturalObject]: ESubcategory.OTHER_AGRICULTURAL_OBJECT,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.OtherLandToBuilding]: ESubcategory.OTHER_LAND_TO_BUILDING,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.OtherAgriculturalLand]: ESubcategory.OTHER_AGRICULTURAL_LAND,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.SecondaryStudioApartment]: ESubcategory.STUDIO_APARTMENT,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.SecondaryThreeRoomApartment]: ESubcategory.THREE_ROOM_APARTMENT,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.SecondaryLandForFamilyHouses]: ESubcategory.LAND_FOR_FAMILY_HOUSE,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.SecondaryRecreationalLand]: ESubcategory.RECREATIONAL_LAND,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.SecondaryCommercialPlots]: ESubcategory.SECONDARY_COMMERCIAL_PLOTS,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.SecondaryGarden]: ESubcategory.SECONDARY_GARDEN,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.SecondaryOrchardsVineyardsHopFields]: ESubcategory.SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.SecondaryAgriculturalAndForestLand]: ESubcategory.SECONDARY_AGRICULTURAL_AND_FOREST_LAND,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.SecondaryGarageAndParking]: ESubcategory.SECONDARY_GARAGE_AND_PARKING,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.SecondaryOfficesAndAdministration]: ESubcategory.SECONDARY_OFFICES_AND_ADMINISTRATION,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.SecondaryTheShop]: ESubcategory.SECONDARY_PRODUCTION,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.SecondaryARestaurant]: ESubcategory.SECONDARY_A_RESTAURANT,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.SecondaryWarehouses]: ESubcategory.SECONDARY_WAREHOUSES,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.SecondaryProduction]: ESubcategory.SECONDARY_PRODUCTION,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.SecondaryAnotherSpaceAndObject]: ESubcategory.SECONDARY_ANOTHER_SPACE_AND_OBJECT,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum.SecondaryHotelGuesthouse]: ESubcategory.SECONDARY_HOTEL_GUESTHOUSE,
};
