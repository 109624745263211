import React, {FC} from 'react';
import {Grid, Palette} from '@mui/material';
import CallIcon from 'modules/theme/components/icons/basic/Call.svg';
import {Svg} from 'modules/theme/components/svg/Svg';
import {Text} from 'modules/theme/components/text/Text';
import Link from 'components/link/Link';

interface IProps {
    phone: string;
    onClick: (phoneNumber: string) => void;
}

export const PhoneBadge: FC<IProps> = ({phone, onClick}) => {
    return <Grid container>
        <Grid
            item
            xs={12}
            padding={1.5}
            border={'1px solid rgba(182, 182, 182, 0.36)'}
            borderRadius={1}
            boxShadow={'0px 2px 4px 0px rgba(0, 0, 0, 0.08)'}
        >
            <Link
                href={`tel: ${phone}`}
                noUnderline
                onClick={() => onClick(phone)}
            >
                <Grid
                    container
                    spacing={1.5}
                >
                    <Grid item xs={2} display={'flex'} justifyContent={'center'} alignContent={'center'}>
                        <Svg icon component={CallIcon} width={40} height={40}/>
                    </Grid>
                    <Grid item xs={10}>
                        <Text variant={'body1'} semibold noWrap>
                            {phone}
                        </Text>
                        <Text
                            variant={'label2'}
                            color={(palette: Palette) => palette.labelTertiary}
                        >
                            Zavolať na mobilné číslo
                        </Text>
                    </Grid>
                </Grid>
            </Link>
        </Grid>
    </Grid>;
};
