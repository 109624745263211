import React, {FC} from 'react';
import {TextArea} from 'modules/theme/components/fields/textArea/TextArea';

interface IProps {
    name: string;
    value: string;
    errorMsg: string;
    onChange: (name: string, value: string) => void;
}

export const ContactFormTextArea: FC<IProps> = ({
    name,
    value,
    errorMsg,
    onChange,
}) => {
    return <TextArea
        rows={5}
        value={value}
        errMsg={errorMsg}
        onChange={
            (value) => {
                onChange(name, value);
            }
        }
    />;
};
