import {
    RealEstateAgencyDetailAdvertisementCategoriesDtoMainCategoryEnum,
} from 'modules/api/generated';
import {
    ECategory
} from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/category/ECategory';

export const DtoCategoryEnumToECategory: Record<RealEstateAgencyDetailAdvertisementCategoriesDtoMainCategoryEnum, ECategory> = {
    [RealEstateAgencyDetailAdvertisementCategoriesDtoMainCategoryEnum.RealEstates]: ECategory.REAL_ESTATES,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoMainCategoryEnum.Apartments]: ECategory.APARTMENTS,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoMainCategoryEnum.Houses]: ECategory.HOUSES,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoMainCategoryEnum.Lands]: ECategory.LANDS,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoMainCategoryEnum.SecondaryHouses]: ECategory.HOUSES,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoMainCategoryEnum.SecondaryLands]: ECategory.LANDS,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoMainCategoryEnum.CottagesAndCabins]: ECategory.COTTAGES_AND_CABINS,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoMainCategoryEnum.SecondaryRecreationalProperties]: ECategory.SECONDARY_RECREATIONAL_PROPERTIES,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoMainCategoryEnum.Objects]: ECategory.OBJECTS,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoMainCategoryEnum.SecondarySpacesAndObjects]: ECategory.SECONDARY_SPACES_AND_OBJECTS,
    [RealEstateAgencyDetailAdvertisementCategoriesDtoMainCategoryEnum.Spaces]: ECategory.SPACES,
};
