import {ECurrency} from 'modules/theme/components/listing/cards/common/enums/parameters/price/ECurrency';
import {EPriceUnit} from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/price/EPriceUnit';

export const priceUnitsRecord = (currency: ECurrency): Record<EPriceUnit, string | ECurrency> => ({
    [EPriceUnit.CURRENCY]: currency,
    [EPriceUnit.CURRENCY_PER_M2]: `${currency}/m²`,
    [EPriceUnit.CURRENCY_PER_DAY]: `${currency}/deň`,
    [EPriceUnit.CURRENCY_PER_YEAR]: `${currency}/rok`,
    [EPriceUnit.CURRENCY_PER_MONTH]: `${currency}/mes.`,
    [EPriceUnit.CURRENCY_PER_M2_PER_YEAR]: `${currency}/m²/rok`,
    [EPriceUnit.CURRENCY_PER_M2_PER_MONTH]: `${currency}/m²/mes.`,
});
