import {RealEstateAgencyDetailAdvertisementPriceInfoPriceDtoCurrencyEnum} from 'modules/api/generated';
import {ECurrency} from 'modules/theme/components/listing/cards/common/enums/parameters/price/ECurrency';

export const DtoPriceCurrencyEnumToECurrencyEnum: Record<RealEstateAgencyDetailAdvertisementPriceInfoPriceDtoCurrencyEnum, ECurrency> = {
    [RealEstateAgencyDetailAdvertisementPriceInfoPriceDtoCurrencyEnum.Eur]: ECurrency.EUR,
    [RealEstateAgencyDetailAdvertisementPriceInfoPriceDtoCurrencyEnum.Czk]: ECurrency.CZK,
    [RealEstateAgencyDetailAdvertisementPriceInfoPriceDtoCurrencyEnum.Gbp]: ECurrency.GBP,
    [RealEstateAgencyDetailAdvertisementPriceInfoPriceDtoCurrencyEnum.Usd]: ECurrency.USD,
    [RealEstateAgencyDetailAdvertisementPriceInfoPriceDtoCurrencyEnum.Pln]: ECurrency.PLN,
    [RealEstateAgencyDetailAdvertisementPriceInfoPriceDtoCurrencyEnum.Huf]: ECurrency.HUF,
    [RealEstateAgencyDetailAdvertisementPriceInfoPriceDtoCurrencyEnum.Ron]: ECurrency.RON,
    [RealEstateAgencyDetailAdvertisementPriceInfoPriceDtoCurrencyEnum.Rsd]: ECurrency.RSD
};
