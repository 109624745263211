import React, {FC} from 'react';
import {Input as InputLabel} from 'modules/theme/components/fields/input/Input';
import {
    InputsMergedWrapper
} from 'modules/theme/components/fields/input/partials/InputsMergedWrapper';
import CloseIcon from 'modules/theme/components/icons/basic/Close.svg';

interface IInputMergedProps {
    valueName: string;
    errorMessageName: string;
    valueEmail: string;
    errorMessageEmail: string;
    valuePhoneNumber: string;
    errorMessagePhoneNumber: string;
    setValue: (field: string, value: string) => void;
}
export const InputsMerged: FC<IInputMergedProps> = ({
    valueName,
    errorMessageName,
    valueEmail,
    errorMessageEmail,
    valuePhoneNumber,
    errorMessagePhoneNumber,
    setValue,
}) => {
    const clearValue = (field: string) => {
        setValue(field, '');
    };

    return <InputsMergedWrapper>
        <InputLabel
            label={'Meno a priezvisko *'}
            value={valueName}
            errorMsg={errorMessageName}
            onChange={
                (value) => {
                    setValue('name', value);
                }
            }
            autoComplete={'name'}
            endIcon={valueName && CloseIcon}
            onEndIconClick={() => {
                clearValue('name');
            }}
        />
        <InputLabel
            label={'Email *'}
            value={valueEmail}
            errorMsg={errorMessageEmail}
            onChange={
                (value) => {
                    setValue('email', value);
                }
            }
            autoComplete={'email'}
            endIcon={valueEmail && CloseIcon}
            onEndIconClick={() => {
                clearValue('email');
            }}
        />
        <InputLabel
            label={'Telefónne číslo *'}
            value={valuePhoneNumber}
            errorMsg={errorMessagePhoneNumber}
            autoComplete={'tel'}
            onChange={
                (value) => {
                    setValue('phoneNumber', value);
                }
            }
            endIcon={valuePhoneNumber && CloseIcon}
            onEndIconClick={() => {
                clearValue('phoneNumber');
            }}
        />
    </InputsMergedWrapper>;
};
