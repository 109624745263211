import React, {FC, FunctionComponent, SVGProps} from 'react';
import {Box, useTheme} from '@mui/material';
import {Svg} from 'modules/theme/components/svg/Svg';
import Link from 'components/link/Link';

interface IProps {
    component: FunctionComponent<SVGProps<SVGSVGElement>>;
    href?: string;
    onClick?: () => void;
    rels?: string[];
}
export const IconBadge: FC<IProps> = ({
    component,
    href,
    onClick,
    rels,
}) => {
    const {palette} = useTheme();

    const onComponentClick = (event) => {
        if (onClick) {
            event.preventDefault();
            onClick();
        }
    };

    return <Box
        display={'flex'}
        alignItems="center"
        justifyContent="center"
        borderRadius={1}
        height={48}
        width={88}
        bgcolor={palette.backgroundSecondary.main}
    >
        <Link
            sx={{
                width: '100%',
                height: '100%',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            href={href}
            openInNewTab
            onClick={onComponentClick}
            rels={rels}
        >
            <Svg icon component={component} width={24} />
        </Link>
    </Box>;
};
