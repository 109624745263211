import {useEffect, useMemo} from 'react';
import { useRecoilState } from 'recoil';
import {IBreadcrumb} from 'components/breadcrumbs/interfaces/IBreadcrumb';
import {breadcrumbsState} from 'modules/state/page/realEstate/state';

interface IReturn {
    breadcrumbs: IBreadcrumb[];
    setBreadcrumbs: (breadcrumbs: IBreadcrumb[]) => void;
}

const useBreadcrumbs = (apiBreadcrumbsData?: IBreadcrumb[]): IReturn => {
    const [ breadcrumbs, setBreadcrumbs ] = useRecoilState(breadcrumbsState);

    const data = useMemo(() => {
        return apiBreadcrumbsData ?? [];
    }, [apiBreadcrumbsData]);

    useEffect((): void => {
        setBreadcrumbs(data);
    }, [data, setBreadcrumbs]);

    return {
        breadcrumbs,
        setBreadcrumbs
    };
};

export default useBreadcrumbs;
