import {formatPrice} from 'modules/theme/components/listing/cards/common/helpers/priceFormater';
import {
    IAdvertisement
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/IAdvertisement';
import {
    RealEstateAgencyDetailAdvertisementOwnerDto,
    RealEstateAgencyDetailAdvertisementVideoDto,
    RealEstateAgencyDetailAdvertisementPhotosDto,
    RealEstateAgencyDetailAdvertisementAddressDto,
    RealEstateAdvertisementListingAdvertisementDto,
    RealEstateAgencyDetailAdvertisementPackagesDto,
    RealEstateAgencyDetailAdvertisementPriceInfoDto,
    RealEstateAgencyDetailAdvertisementVideoDtoTypeEnum,
    RealEstateAgencyDetailAdvertisementPriceInfoPriceDto, RealEstateAgencyDetailAdvertisementAgencyDto,
} from 'modules/api/generated';
import {
    IPhotos
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/photos/IPhotos';
import {
    IAddress
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/address/IAddress';
import {
    DtoSubCategoryEnumToESubcategory
} from 'components/page/realEstate/common/advertisements/constants/parameters/DtoSubCatergoryEnumToESubcategory';
import {
    IPrice
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/price/IPrice';
import {
    DtoPriceOptionEnumToEPriceOptionEnum
} from 'components/page/realEstate/common/advertisements/constants/parameters/price/DtoPriceOptionEnumToEPriceOptionEnum';
import {
    DtoPriceCurrencyEnumToECurrencyEnum
} from 'components/page/realEstate/common/advertisements/constants/parameters/price/DtoPriceCurrencyEnumToECurrencyEnum';
import {
    IPackages
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/packages/IPackages';
import {
    IAttachments
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/attachments/IAttachments';
import {
    ISeller
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/seller/ISeller';
import {
    DtoPriceUnitEnumToEUnitEnum
} from 'components/page/realEstate/common/advertisements/constants/parameters/price/DtoPriceUnitEnumToEPriceUnitEnum';
import {
    ECategory
} from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/category/ECategory';
import {DtoCategoryEnumToECategory} from 'components/page/realEstate/common/advertisements/constants/parameters/DtoCategoryEnumToECategory';
import {
    ETransaction
} from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/transaction/ETransaction';
import {DtoTransactionEnumToETransaction} from 'components/page/realEstate/common/advertisements/constants/parameters/DtoTransactionEnumToETransaction';
import {
    ERealEstateState
} from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/realEstateState/ERealEstateState';
import {
    DtoRealEstateStateEnumToERealEstateState
} from 'components/page/realEstate/common/advertisements/constants/parameters/DtoRealEstateStateEnumToERealEstateState';
import {
    IAgency
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/agency/IAgency';

export const realEstateAdvertisementListingAdvertisementDtoToIAdvertisementMapper = (isAgencyProfi: boolean, advertisementDto: RealEstateAdvertisementListingAdvertisementDto): IAdvertisement => {

    const {
        id,
        slug,
        area,
        title,
        externalId,
        description,
        isDevProject,
        photos: advDtoPhotos,
        videos: advDtoVideos,
        address: advDtoAddress,
        packages: advDtoPackages,
        priceInfo: advDtoPriceInfo,
        ownerInfo: advDtoSellerInfo,
        agencyInfo: advDtoAgencyInfo,
        categories: advDtoCategories,
        transaction: advDtoTransaction,
        floorPlanUrl: advDtoFloorPlanUrl,
        realEstateState: advDtoRealEstateState,
    } = advertisementDto;

    const price: IPrice = getPrices(advDtoPriceInfo);
    const photos: IPhotos = mapPhotosDtoToIphotos(advDtoPhotos);
    const seller: ISeller = mapOwnerDtoToISeller(advDtoSellerInfo, isAgencyProfi);
    const address: IAddress = mapAddressDtoToIAddress(advDtoAddress);
    const packages: IPackages = mapPackagesDtoToIPackages(advDtoPackages);
    const attachments: IAttachments = getIAttachments(advDtoFloorPlanUrl, advDtoVideos);
    const category: ECategory = DtoCategoryEnumToECategory[advDtoCategories.mainCategory];
    const transaction: ETransaction = DtoTransactionEnumToETransaction[advDtoTransaction];
    const realEstateState: ERealEstateState = DtoRealEstateStateEnumToERealEstateState[advDtoRealEstateState] || null;
    const subcategory = DtoSubCategoryEnumToESubcategory[advDtoCategories.subCategory] || null;
    const agency = mapAgencyInfoDtoToIAgency(advDtoAgencyInfo);

    return {
        id,
        slug,
        area,
        title,
        price,
        seller,
        agency,
        photos,
        address,
        packages,
        category,
        externalId,
        description,
        transaction,
        subcategory,
        attachments,
        isDevProject,
        realEstateState,
    };
};

const mapPhotosDtoToIphotos = ({
    previewPhotoUrl,
    thumbPhotosUrl
}: RealEstateAgencyDetailAdvertisementPhotosDto): IPhotos => ({
    previewPhotoUrl: previewPhotoUrl,
    thumbsPhotosUrl: thumbPhotosUrl,
});

const mapAddressDtoToIAddress = ({
    country: locationCountry,
    county,
    district,
    city,
    street,
    streetNumber,
    isForeign
}: RealEstateAgencyDetailAdvertisementAddressDto): IAddress => {

    const country = isForeign ? locationCountry : null;
    const streetAndSteetNum = street && streetNumber ? `${street} ${streetNumber}` : street ? street : '';

    const locationArray = district
        ? [streetAndSteetNum, `${city} (${district})`, country]
        : [street, city, country];

    return {
        location: locationArray.filter(val => val).join(', '),
        country: locationCountry,
        county: county || null,
        region: district || null,
        city: city || null,
        street: street || null,
        streetNumber: streetNumber || null,
    };
};

const mapPackagesDtoToIPackages = ({
    isTop,
    isPremium
}: RealEstateAgencyDetailAdvertisementPackagesDto): IPackages => ({
    isTop: isTop,
    isPremium: isPremium,
});

const mapOwnerDtoToISeller = ({
    isPro,
    photoUrl,
    ownerName,
}: RealEstateAgencyDetailAdvertisementOwnerDto, isAgencyProfi?: boolean): ISeller => ({
    isSellerPro: isPro,
    sellerPhotoUrl: photoUrl,
    isAgencyProfi: isAgencyProfi,
    sellerName: ownerName,
});

const mapAgencyInfoDtoToIAgency = ({
    agencyName,
}: RealEstateAgencyDetailAdvertisementAgencyDto): IAgency => ({
    nameOfAdvertiser: agencyName,
});

const getIAttachments = (floorPlanUrl?: string, videos?: RealEstateAgencyDetailAdvertisementVideoDto[]): IAttachments => {

    const videoTypes = [
        RealEstateAgencyDetailAdvertisementVideoDtoTypeEnum.Youtu,
        RealEstateAgencyDetailAdvertisementVideoDtoTypeEnum.Youtube,
    ];

    return {
        hasFloorPlan: !!floorPlanUrl,
        has3d: !!videos.some(video => !videoTypes.includes(video.type)),
        hasVideo: !!videos.some(video => videoTypes.includes(video.type)),
    };
};

const getPrices = ({
    priceOption,
    priceSecondary,
    pricePrimary
}: RealEstateAgencyDetailAdvertisementPriceInfoDto): IPrice => {

    let mainPrice = '';
    let unitPrice = undefined;

    if (priceOption) {
        mainPrice = DtoPriceOptionEnumToEPriceOptionEnum[priceOption];
    }

    if (pricePrimary && !!pricePrimary.value) {
        mainPrice = getPrice(pricePrimary);
    }

    if (priceSecondary && !!priceSecondary.value) {
        unitPrice = getPrice(priceSecondary);
    }

    return {
        price: mainPrice,
        ...(unitPrice !== undefined && {unitPrice}),
    };
};

const getPrice = ({
    value,
    unit,
    currency
}: RealEstateAgencyDetailAdvertisementPriceInfoPriceDto): string => formatPrice(value, DtoPriceCurrencyEnumToECurrencyEnum[currency], DtoPriceUnitEnumToEUnitEnum[unit]);

