import {
    RealEstateAdvertisementListingAdvertisementDtoRealEstateStateEnum,
} from 'modules/api/generated';
import {
    ERealEstateState
} from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/realEstateState/ERealEstateState';

export const DtoRealEstateStateEnumToERealEstateState: Record<RealEstateAdvertisementListingAdvertisementDtoRealEstateStateEnum, ERealEstateState> = {
    [RealEstateAdvertisementListingAdvertisementDtoRealEstateStateEnum.CompleteReconstruction]: ERealEstateState.COMPLETE_RECONSTRUCTION,
    [RealEstateAdvertisementListingAdvertisementDtoRealEstateStateEnum.DevelopProject]: ERealEstateState.DEVELOP_PROJECT,
    [RealEstateAdvertisementListingAdvertisementDtoRealEstateStateEnum.Finished]: ERealEstateState.FINISHED,
    [RealEstateAdvertisementListingAdvertisementDtoRealEstateStateEnum.IntendedToDemolition]: ERealEstateState.INTENDED_TO_DEMOLITION,
    [RealEstateAdvertisementListingAdvertisementDtoRealEstateStateEnum.Newbuilding]: ERealEstateState.NEWBUILDING,
    [RealEstateAdvertisementListingAdvertisementDtoRealEstateStateEnum.PartialReconstruction]: ERealEstateState.PARTIAL_RECONSTRUCTION,
    [RealEstateAdvertisementListingAdvertisementDtoRealEstateStateEnum.Preparation]: ERealEstateState.PREPARATION,
    [RealEstateAdvertisementListingAdvertisementDtoRealEstateStateEnum.OriginalCondition]: ERealEstateState.ORIGINAL_CONDITION,
    [RealEstateAdvertisementListingAdvertisementDtoRealEstateStateEnum.UnderConstruction]: ERealEstateState.UNDER_CONSTRUCTION,
    [RealEstateAdvertisementListingAdvertisementDtoRealEstateStateEnum.SoldOut]: ERealEstateState.SOLD_OUT,
};
