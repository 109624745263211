export enum ECurrency {
    EUR = '€',
    CZK = 'Kč',
    GBP = '£',
    USD = '$',
    PLN = 'Zł',
    HUF = 'Ft',
    RON = 'Lei',
    RSD = 'Дин.',
}
