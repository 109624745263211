import {
    IAdvertisement
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/IAdvertisement';
import {
    IAdvertisementSelectItemEvent
} from 'modules/gtmEvents/interfaces/view/advertisement/IAdvertisementSelectItemEvent';
import {
    IAdvertisementSelectPromotionEvent
} from 'modules/gtmEvents/interfaces/view/advertisement/IAdvertisementSelectPromotionEvent';
import {EGtmEvent} from 'modules/gtmEvents/enums/EGtmEvent';
import {ECreativeSlot} from 'modules/gtmEvents/enums/ECreativeSlot';
import {EPromotionId} from 'modules/gtmEvents/enums/EPromotionId';
import {IGenerateLead} from 'modules/gtmEvents/interfaces/generateLead/IGenerateLead';
import {EFormState} from 'modules/gtmEvents/enums/EFormState';
import {EFormStep} from 'modules/gtmEvents/enums/EFormStep';
import {EPageType} from 'modules/gtmEvents/enums/EPageType';
import {EMethod} from 'modules/gtmEvents/enums/EMethod';
import {IPurchaseEvent} from 'modules/gtmEvents/interfaces/purchase/IPurchaseEvent';
import {ECurrency} from 'modules/gtmEvents/enums/ECurrency';
import {IShowEvent} from 'modules/gtmEvents/interfaces/showEvent/IShowEvent';
import {
    IAdvertisementViewItemListEvent
} from 'modules/gtmEvents/interfaces/view/advertisement/IAdvertisementViewItemListEvent';
import {EItemListId} from 'modules/gtmEvents/enums/EItemListId';
import {IAgency} from 'modules/realEstate/agency/listing/interfaces/agency/IAgency';
import {
    IUserViewItemListEvent
} from 'modules/gtmEvents/interfaces/view/user/IUserViewItemListEvent';
import {IAgent} from 'modules/realEstate/agent/listing/interfaces/partials/IAgent';
import {IUserSelectItemEvent} from 'modules/gtmEvents/interfaces/view/user/IUserSelectItemEvent';
import {
    IUserSelectPromotionEvent
} from 'modules/gtmEvents/interfaces/view/user/IUserSelectPromotionEvent';
import {IAdvertisementViewPromotionEvent} from 'modules/gtmEvents/interfaces/view/advertisement/IAdvertisementViewPromotionEvent';
import {IUserViewPromotionEvent} from 'modules/gtmEvents/interfaces/view/user/IUserViewPromotionEvent';
import {IProfiAgency} from 'modules/realEstate/common/interfaces/IProfiAgency';
import {IProfiAgent} from 'modules/realEstate/common/interfaces/IProfiAgent';
import {
    getAdvertisementItem,
    getAgencyItem,
    getAgentItem, getViewItemListAdvertisements,
    getViewItemListAgencies, getViewItemListAgents,
    getViewItemListLandingProfiAgencies, getViewItemListLandingProfiAgents
} from 'modules/gtmEvents/helpers/events/realEstate/partials/eventsDataHelper';
import {IOnVisiblePhoneClickEvent} from 'modules/gtmEvents/interfaces/click/IOnVisiblePhoneClickEvent';

export const getAdvertisementSelectItemEvent = (advertisement: IAdvertisement, index: number): IAdvertisementSelectItemEvent => {
    return {
        event: EGtmEvent.SELECT_ITEM,
        ecommerce: {
            item_list_id: EItemListId.ASSOCIATED_LISTINGS,
            item_list_name: EItemListId.ASSOCIATED_LISTINGS,
            items: [
                getAdvertisementItem(advertisement, index),
            ],
        }
    };
};

export const getAdvertisementSelectPromotionEvent = (advertisement: IAdvertisement, index: number): IAdvertisementSelectPromotionEvent => {
    let promotionId = null;
    if (advertisement.packages.isPremium) {
        promotionId = EPromotionId.PREMIUM_OF_LIST;
    }
    if (advertisement.packages.isTop) {
        promotionId = EPromotionId.TOP_OF_LIST;
    }

    return {
        event: EGtmEvent.SELECT_PROMOTION,
        ecommerce: {
            creative_slot: ECreativeSlot.LIST,
            promotion_id: promotionId,
            promotion_name: promotionId,
            items: [
                getAdvertisementItem(advertisement, index),
            ],
        }
    };
};

export const getAgencySelectItemEvent = (agency: IAgency, index: number): IUserSelectItemEvent => {
    return {
        event: EGtmEvent.SELECT_ITEM,
        ecommerce: {
            item_list_id: EItemListId.ASSOCIATED_LISTINGS,
            item_list_name: EItemListId.ASSOCIATED_LISTINGS,
            items: [
                getAgencyItem(agency, index),
            ],
        }
    };
};

export const getAgencySelectPromotionEvent = (agency: IAgency, index: number): IUserSelectPromotionEvent => {
    return {
        event: EGtmEvent.SELECT_PROMOTION,
        ecommerce: {
            creative_slot: ECreativeSlot.LIST,
            promotion_id: EPromotionId.TOP_OF_LIST,
            promotion_name: EPromotionId.TOP_OF_LIST,
            items: [
                getAgencyItem(agency, index),
            ],
        }
    };
};

export const getAgentSelectItemEvent = (agent: IAgent, index: number): IUserSelectItemEvent => {
    return {
        event: EGtmEvent.SELECT_ITEM,
        ecommerce: {
            item_list_id: EItemListId.ASSOCIATED_LISTINGS,
            item_list_name: EItemListId.ASSOCIATED_LISTINGS,
            items: [
                getAgentItem(agent, index),
            ],
        }
    };
};

export const getAgentSelectPromotionEvent = (agent: IAgent, index: number): IUserSelectPromotionEvent => {
    return {
        event: EGtmEvent.SELECT_PROMOTION,
        ecommerce: {
            creative_slot: ECreativeSlot.LIST,
            promotion_id: EPromotionId.TOP_OF_LIST,
            promotion_name: EPromotionId.TOP_OF_LIST,
            items: [
                getAgentItem(agent, index),
            ],
        }
    };
};

export const getGenerateLeadEvent = (userId: string, userName: string, isAgent: boolean, formState: EFormState, formStep: EFormStep, method: EMethod, parentUserName?: string, isLoggedIn?: boolean): IGenerateLead => {
    let affiliation = userName;
    if (parentUserName) {
        affiliation = `${affiliation}, ${parentUserName}`;
    }

    return {
        event: EGtmEvent.GENERATE_LEAD,
        method,
        form_state: formState,
        form_step: formStep,
        page_type: isAgent ? EPageType.AGENT : EPageType.AGENCY,
        affiliation,
        affiliation_number: userId,
        item_id: userId,
        items: {
            item_id: userId,
        },
        is_loggedin: isLoggedIn ? 'true' : 'undefined',
    };
};

export const getPurchaseEvent = (
    userId: string,
    userName: string,
    parentUserName?: string,
    value?: number
): IPurchaseEvent => {
    let affiliation = userName;
    if (parentUserName) {
        affiliation = `${affiliation}, ${parentUserName}`;
    }

    return {
        event: EGtmEvent.PURCHASE,
        ecommerce: {
            transaction_id: `${userName}_${new Date().getTime()}`,
            currency: ECurrency.EUR,
            value: value ? value : 1,
            items: [
                {
                    item_id: userId,
                    price: value ? value : 1,
                    affiliation,
                }
            ]
        }
    };
};

export const getAddToCartEvent = (
    userId: string,
    userName: string,
    parentUserName?: string,
    value?: number
): IPurchaseEvent => {
    let affiliation = userName;
    if (parentUserName) {
        affiliation = `${affiliation}, ${parentUserName}`;
    }

    return {
        event: EGtmEvent.ADD_TO_CART,
        ecommerce: {
            currency: ECurrency.EUR,
            value: value ? value : 1,
            transaction_id: `${userName}_${new Date().getTime()}`,
            items: [
                {
                    item_id: userId,
                    price: value ? value : 1,
                    affiliation,
                }
            ]
        }
    };
};

export const getOpenWebEvent = (userId: string, userName: string, isAgent: boolean, parentUserName?: string): IShowEvent => {
    let affiliation = userName;
    if (!isAgent && parentUserName) {
        affiliation = `${affiliation}, ${parentUserName}`;
    }

    return {
        event: EGtmEvent.OPEN_WEB,
        page_type: isAgent ? EPageType.AGENT : EPageType.AGENCY,
        affiliation,
        affiliation_number: userId,
        items: {
            item_id: userId,
        },
    };
};

export const getViewItemListAgenciesEvent = (agencies: IAgency[], currentPage: number, limit: number): IUserViewItemListEvent => {
    return {
        event: EGtmEvent.VIEW_ITEM_LIST,
        ecommerce: {
            item_list_id: EItemListId.DIRECTORY_SEARCH_RESULT,
            item_list_name: EItemListId.DIRECTORY_SEARCH_RESULT,
            items: getViewItemListAgencies(agencies, currentPage, limit),
        }
    };
};

export const getViewItemListLandingAgenciesEvent = (agencies: IProfiAgency[], currentPage: number, limit: number): IUserViewItemListEvent => {
    return {
        event: EGtmEvent.VIEW_ITEM_LIST,
        ecommerce: {
            item_list_id: EItemListId.DIRECTORY_SEARCH_RESULT,
            item_list_name: EItemListId.DIRECTORY_SEARCH_RESULT,
            items: getViewItemListLandingProfiAgencies(agencies, currentPage, limit),
        }
    };
};

export const getViewItemListUsersPromotionLandingProfiAgenciesEvent = (agencies: IProfiAgency[], currentPage: number, limit: number): IUserViewPromotionEvent => {
    return {
        event: EGtmEvent.VIEW_PROMOTION,
        ecommerce: {
            creative_slot: ECreativeSlot.LIST,
            promotion_id: EPromotionId.TOP_OF_LIST,
            promotion_name: EPromotionId.TOP_OF_LIST,
            items: getViewItemListLandingProfiAgencies(agencies, currentPage, limit),
        }
    };
};

export const getViewItemListLandingAgentsEvent = (agents: IProfiAgent[], currentPage: number, limit: number): IUserViewItemListEvent => {
    return {
        event: EGtmEvent.VIEW_ITEM_LIST,
        ecommerce: {
            item_list_id: EItemListId.DIRECTORY_SEARCH_RESULT,
            item_list_name: EItemListId.DIRECTORY_SEARCH_RESULT,
            items: getViewItemListLandingProfiAgents(agents, currentPage, limit),
        }
    };
};

export const getViewItemListUsersPromotionEvent = (agencies: IAgency[], currentPage: number, limit: number): IUserViewPromotionEvent => {
    return {
        event: EGtmEvent.VIEW_PROMOTION,
        ecommerce: {
            creative_slot: ECreativeSlot.LIST,
            promotion_id: EPromotionId.TOP_OF_LIST,
            promotion_name: EPromotionId.TOP_OF_LIST,
            items: getViewItemListAgencies(agencies, currentPage, limit),
        }
    };
};

export const getViewItemListAgentsEvent = (agents: IAgent[], currentPage: number, limit: number): IUserViewItemListEvent => {
    return {
        event: EGtmEvent.VIEW_ITEM_LIST,
        ecommerce: {
            item_list_id: EItemListId.DIRECTORY_SEARCH_RESULT,
            item_list_name: EItemListId.DIRECTORY_SEARCH_RESULT,
            items: getViewItemListAgents(agents, currentPage, limit),
        }
    };
};

export const getViewItemListAdvertisementsEvent = (advertisements: IAdvertisement[], currentPage: number, limit: number): IAdvertisementViewItemListEvent => {
    return {
        event: EGtmEvent.VIEW_ITEM_LIST,
        ecommerce: {
            item_list_id: EItemListId.CLASSIFIED_SEARCH_RESULTS,
            item_list_name: EItemListId.CLASSIFIED_SEARCH_RESULTS,
            items: getViewItemListAdvertisements(advertisements, currentPage, limit),
        }
    };
};

export const getViewItemListAdvertisementsPromotionPremiumEvent = (advertisements: IAdvertisement[], currentPage: number, limit: number): IAdvertisementViewPromotionEvent => {
    return {
        event: EGtmEvent.VIEW_PROMOTION,
        ecommerce: {
            creative_slot: ECreativeSlot.LIST,
            promotion_id: EPromotionId.PREMIUM_OF_LIST,
            promotion_name: EPromotionId.PREMIUM_OF_LIST,
            items: getViewItemListAdvertisements(advertisements, currentPage, limit),
        }
    };
};

export const getViewItemListAdvertisementsPromotionTopEvent = (advertisements: IAdvertisement[], currentPage: number, limit: number): IAdvertisementViewPromotionEvent => {
    return {
        event: EGtmEvent.VIEW_PROMOTION,
        ecommerce: {
            creative_slot: ECreativeSlot.LIST,
            promotion_id: EPromotionId.TOP_OF_LIST,
            promotion_name: EPromotionId.TOP_OF_LIST,
            items: getViewItemListAdvertisements(advertisements, currentPage, limit),
        }
    };
};

export const getClickPhoneNumberEvent = (isAgent?: boolean): IOnVisiblePhoneClickEvent => {
    return {
        event: EGtmEvent.CLICK_ON_VISIBLE_PHONE_NUMBER,
        page_type: isAgent ? EPageType.AGENT : EPageType.AGENCY,
    };
};
