import {FC} from 'react';
import Link from 'components/link/Link';
import {Text} from 'modules/theme/components/text/Text';
import {CheckboxWithLabel} from 'modules/theme/components/fields/checkbox/CheckboxWithLabel';
import {Palette} from '@mui/material';
import { principlesOfProtection } from 'modules/common/links/principlesOfProtection/principlesOfProtection';

interface ISelect {
    isChecked: boolean;
    errorMsg?: string;
    onChange: (isChecked: boolean) => void;
}

export const AgreeCheckbox: FC<ISelect> = ({isChecked, onChange, errorMsg}) => {
    return <CheckboxWithLabel
        checkboxTopAlign
        onClick={onChange}
        isChecked={isChecked}
        errMsg={errorMsg}
        label={
            <Text
                variant="label2"
                color={(palette: Palette) => palette.labelSecondary}
            >
                Vaše údaje zadané do kontaktného formulára budú odoslané konkrétnemu predajcovi a aj spoločnosti United
                Classifieds, s.r.o. Bližšie informácie k ochrane osobných údajov nájdete <Link openInNewTab sx={{textDecoration: 'underline'}}
                    href={principlesOfProtection}>tu</Link>.
            </Text>
        }
    />;

};
