import {IContactFormData} from 'components/page/realEstate/common/interfaces/IContactFormData';
import useNotification from 'modules/notification/useNotification';
import {useState} from 'react';
import {realEstateAgencyDetailApi, realEstateAgentDetailApi} from 'modules/api/client';
import useLoading from 'modules/state/app/hook/useLoading';

interface IReturn {
    isSending: boolean;
    onSubmit: (formData: IContactFormData) => void;
}
export const useSubmitContactForm = (entityId: string, afterSuccessEmailIsSent?: () => void, afterFailEmailIsSent?: () => void, isAgent?: boolean): IReturn => {
    const { start: startSending, stop: stopSending } = useLoading();
    const {success: showSuccessNotification , error: showErrorNotification} = useNotification();
    const [isSending, setIsSending] = useState<boolean>(false);

    const onSubmit = async (formData: IContactFormData) => {
        if (!entityId || !formData.recaptchaToken) {
            showErrorNotification('Správu sa nepodarilo odoslať');
            return;
        }
        try {
            setIsSending(true);
            startSending();
            const recaptchaToken = formData.recaptchaToken;
            delete formData.recaptchaToken;
            let response;
            if (isAgent) {
                response = await realEstateAgentDetailApi.notificationControllerSendEmailToAgent(entityId, formData, {
                    headers: {
                        'recaptcha-token': recaptchaToken,
                        'recaptcha-type': 'tickbox',
                    }
                });

            } else {
                response = await realEstateAgencyDetailApi.sendEmailFromAgencyDetailPage(entityId, formData, {
                    headers: {
                        'recaptcha-token': recaptchaToken,
                        'recaptcha-type': 'tickbox',
                    }
                });
            }

            if (response.status !== 201) {
                throw `Response status was: ${response.status}`;
            }

            showSuccessNotification('Vaša správa bola odoslaná');

            if (typeof afterSuccessEmailIsSent === 'function') {
                afterSuccessEmailIsSent();
            }
        } catch (error) {
            showErrorNotification('Správu sa nepodarilo odoslať');

            if (typeof afterFailEmailIsSent === 'function') {
                afterFailEmailIsSent();
            }
        } finally {
            setIsSending(false);
            stopSending();
        }
    };

    return {
        isSending,
        onSubmit,
    };
};
