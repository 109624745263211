import * as Yup from 'yup';
import {IContactFormData} from 'components/page/realEstate/common/interfaces/IContactFormData';

export const contactFormValidationSchema = Yup.object().shape<Record<keyof IContactFormData, Yup.AnySchema>>({
    text: Yup
        .string()
        .max(500, 'Správa môže mať maximálne 500 znakov')
        .required('Správa je povinná'),
    name: Yup
        .string()
        .min(3, 'Meno musí mať aspoň 3 znaky')
        .max(50, 'Meno môže mať maximálne 50 znakov')
        .required('Meno je povinné'),
    email: Yup
        .string()
        .email('Neplatný formát emailu')
        .required('Email je povinný'),
    phoneNumber: Yup
        .string()
        .trim()
        .matches(/^(?:02\/?(?:[0-9]\s*){8}|0[3-5][1-9]\/?[0-9]{7}|(?:\+421|0)\s*(?:[0-9]\s*){9}|\+420\s*(?:[0-9]\s*){9})$/, 'Číslo je v nesprávnom formáte.')
        .required('Číslo je povinné'),
    agreeToTerms: Yup
        .boolean()
        .oneOf([true], 'Súhlas je povinný'),
    recaptchaToken: Yup
        .string()
        .required('Je potrebné vyplniť reCAPTCHA'),
});
