import React, {FC} from 'react';
import {Grid} from '@mui/material';
import {IconMobileBadge} from 'components/page/realEstate/common/drawer/partials/IconMobileBadge';
import WebIcon from 'modules/theme/components/icons/basic/Web.svg';
import MessengerIcon from 'modules/theme/components/icons/social/Messenger.svg';
import WhatsappIcon from 'modules/theme/components/icons/social/Whatsapp.svg';
import { MESSENGER_URL, WHATSAPP_URL } from 'components/page/realEstate/common/contacts/constats';

interface IProps {
    web?: string;
    onWebClick?: () => void;
    messenger?: string;
    onMessengerClick?: () => void;
    whatsapp?: string;
    onWhatsappClick?: () => void;
}

export const ContactBadges: FC<IProps> = ({
    web,
    onWebClick,
    messenger,
    onMessengerClick,
    whatsapp,
    onWhatsappClick,
}) => {
    const getItemCount = () => {
        let count = 0;
        if (web) count++;
        if (messenger) count++;
        if (whatsapp) count++;

        return count;
    };

    const itemCount = getItemCount();
    return <Grid container spacing={1}>
        {web && <Grid item xs={12 / itemCount}>
            <IconMobileBadge
                href={web}
                name="Web"
                component={WebIcon}
                rels={['sponsored']}
                onClick={onWebClick}
            />
        </Grid>}
        {messenger && <Grid item xs={12 / itemCount}>
            <IconMobileBadge
                href={`${MESSENGER_URL}/${messenger}`}
                name={'Messenger'}
                component={MessengerIcon}
                onClick={onMessengerClick}
            />
        </Grid>}
        {whatsapp && <Grid item xs={12 / itemCount}>
            <IconMobileBadge
                href={`${WHATSAPP_URL}/${whatsapp}`}
                name={'Whatsapp'}
                component={WhatsappIcon}
                onClick={onWhatsappClick}
            />
        </Grid>}
    </Grid>;
};
