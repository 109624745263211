import {
    IAdvertisement
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/IAdvertisement';
import {IAdvertisementItem} from 'modules/gtmEvents/interfaces/view/advertisement/partials/IAdvertisementItem';
import {
    getGtmLocationStringHelper,
    getItemVariant,
    getOrderIndex
} from 'modules/gtmEvents/helpers/gtmHelper';
import {EItemBrand} from 'modules/gtmEvents/enums/EItemBrand';
import {EItemListId} from 'modules/gtmEvents/enums/EItemListId';
import {IAgency} from 'modules/realEstate/agency/listing/interfaces/agency/IAgency';
import {IUserItem} from 'modules/gtmEvents/interfaces/view/user/partials/IUserItem';
import {IAgent} from 'modules/realEstate/agent/listing/interfaces/partials/IAgent';
import {IProfiAgency} from 'modules/realEstate/common/interfaces/IProfiAgency';
import {IProfiAgent} from 'modules/realEstate/common/interfaces/IProfiAgent';

export const getAdvertisementItem = (advertisement: IAdvertisement, index: number): IAdvertisementItem => {
    const location = getGtmLocationStringHelper(
        advertisement.address.country,
        advertisement.address.county,
        advertisement.address.region,
        advertisement.address.city,
    );

    const affiliationPrepare = [];
    if (advertisement.agency.nameOfAdvertiser) {
        affiliationPrepare.push(advertisement.agency.nameOfAdvertiser);
    }
    if (advertisement.seller.sellerName) {
        affiliationPrepare.push(advertisement.seller.sellerName);
    }

    let price = '';
    if (advertisement.price.price) {
        price = advertisement.price.price.replace(/[^\d,.]/g, '');
    }

    return {
        item_id: `LT_${advertisement.id}`,
        item_name: advertisement.title,
        affiliation: affiliationPrepare.join(', '),
        index,
        item_brand: EItemBrand.CLASSIFIED_LISTING,
        item_category: `${advertisement.category} ${(advertisement.transaction).toLowerCase()}`,
        item_category2: `${advertisement.subcategory} ${(advertisement.transaction).toLowerCase()}`,
        item_category3: location,
        item_category4: advertisement.transaction,
        item_category5: advertisement.realEstateState,
        item_list_id: EItemListId.ASSOCIATED_LISTINGS,
        item_list_name: EItemListId.ASSOCIATED_LISTINGS,
        item_variant: getItemVariant(advertisement.photos.previewPhotoUrl, advertisement.attachments.hasVideo),
        location_id: location,
        price,
        quantity: 1,
    };
};

export const getAgencyItem = (agency: IAgency, index: number): IUserItem => {
    const location = getGtmLocationStringHelper(
        agency.agencyAddress?.country,
        agency.agencyAddress.county,
        agency.agencyAddress.region,
        agency.agencyAddress.city,
    );

    return {
        item_id: `BZ_${agency.agencyId}`,
        item_name: agency.agencyName,
        affiliation: agency.agencyName,
        index,
        item_brand: EItemBrand.DIRECTORY_LISTING,
        item_list_id: EItemListId.ASSOCIATED_LISTINGS,
        item_list_name: EItemListId.ASSOCIATED_LISTINGS,
        item_variant: getItemVariant(agency.agencyLogoUrl),
        location_id: location,
        quantity: 1,
    };
};

export const getAgentItem = (agent: IAgent, index: number): IUserItem => {
    return {
        item_id: `BZ_${agent.agentId}`,
        item_name: agent.agentName,
        affiliation: agent.agentName,
        index,
        item_brand: EItemBrand.DIRECTORY_LISTING,
        item_list_id: EItemListId.ASSOCIATED_LISTINGS,
        item_list_name: EItemListId.ASSOCIATED_LISTINGS,
        item_variant: getItemVariant(agent.agentPreviewPhotoUrl),
        location_id: 'undefined',
        quantity: 1,
    };
};

export const getViewItemListAdvertisements = (advertisements: IAdvertisement[], currentPage: number, limit: number): IAdvertisementItem[] => {
    return advertisements.map((advertisement, index) => {
        return getAdvertisementItem(
            advertisement,
            getOrderIndex(index, currentPage, limit),
        );
    });
};

export const getViewItemListAgencies = (agencies: IAgency[], currentPage: number, limit: number): IUserItem[] => {
    return agencies.map((agency, index) => ({
        item_id: agency.agencyId,
        item_name: agency.agencyName,
        affiliation: agency.agencyName,
        index: getOrderIndex(index, currentPage, limit),
        item_brand: EItemBrand.DIRECTORY_LISTING,
        item_list_id: EItemListId.DIRECTORY_SEARCH_RESULT,
        item_list_name: EItemListId.DIRECTORY_SEARCH_RESULT,
        item_variant: getItemVariant(agency.agencyLogoUrl),
        location_id: getGtmLocationStringHelper(
            agency.agencyAddress?.country,
            agency.agencyAddress?.county,
            agency.agencyAddress?.region,
            agency.agencyAddress?.city,
        ),
        quantity: 1,
    }));
};

export const getViewItemListAgents = (agents: IAgent[], currentPage: number, limit: number): IUserItem[] => {
    return agents.map((agent, index) => ({
        item_id: agent.agentId,
        item_name: agent.agentName,
        affiliation: `${agent.agentName}, ${agent.agency.agencyName}`,
        index: getOrderIndex(index, currentPage, limit),
        item_brand: EItemBrand.DIRECTORY_LISTING,
        item_list_id: EItemListId.DIRECTORY_SEARCH_RESULT,
        item_list_name: EItemListId.DIRECTORY_SEARCH_RESULT,
        item_variant: getItemVariant(agent.agentPreviewPhotoUrl),
        location_id: 'undefined',
        quantity: 1,
    }));
};

export const getViewItemListLandingProfiAgencies = (agencies: IProfiAgency[], currentPage: number, limit: number): IUserItem[] => {
    return agencies.map((agency, index) => ({
        item_id: agency.id,
        item_name: agency.agencyName,
        affiliation: agency.agencyName,
        index: getOrderIndex(index, currentPage, limit),
        item_brand: EItemBrand.DIRECTORY_LISTING,
        item_list_id: EItemListId.DIRECTORY_SEARCH_RESULT,
        item_list_name: EItemListId.DIRECTORY_SEARCH_RESULT,
        item_variant: getItemVariant(agency.agencyLogoPreviewImgUrl),
        location_id: getGtmLocationStringHelper(
            agency.agencyAddress?.country,
            agency.agencyAddress?.county,
            agency.agencyAddress?.region,
            agency.agencyAddress?.city,
        ),
        quantity: 1,
    }));
};

export const getViewItemListLandingProfiAgents = (agents: IProfiAgent[], currentPage: number, limit: number): IUserItem[] => {
    return agents.map((agent, index) => ({
        item_id: agent.userId,
        item_name: agent.userName,
        affiliation: `${agent.userName}, ${agent.agencyName}`,
        index: getOrderIndex(index, currentPage, limit),
        item_brand: EItemBrand.DIRECTORY_LISTING,
        item_list_id: EItemListId.DIRECTORY_SEARCH_RESULT,
        item_list_name: EItemListId.DIRECTORY_SEARCH_RESULT,
        item_variant: getItemVariant(agent.userPreviewPhotoUrl),
        location_id: 'undefined',
        quantity: 1,
    }));
};
