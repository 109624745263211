export enum ERealEstateState {
    NEWBUILDING = 'Novostavba',
    PARTIAL_RECONSTRUCTION = 'Čiastočná rekonštrukcia',
    COMPLETE_RECONSTRUCTION = 'Kompletná rekonštrukcia',
    ORIGINAL_CONDITION = 'Pôvodný stav',
    UNDER_CONSTRUCTION = 'Vo výstavbe',
    DEVELOP_PROJECT = 'Developérsky projekt',
    INTENDED_TO_DEMOLITION = 'Určený k demolácii',
    PREPARATION = 'V príprave',
    FINISHED = 'Ukončený',
    SOLD_OUT = 'Vypredaný',
}
