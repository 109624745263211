import {RealEstateAgencyDetailAdvertisementPriceInfoPriceDtoUnitEnum} from 'modules/api/generated';
import {EPriceUnit} from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/price/EPriceUnit';

export const DtoPriceUnitEnumToEUnitEnum: Record<RealEstateAgencyDetailAdvertisementPriceInfoPriceDtoUnitEnum, EPriceUnit> = {
    [RealEstateAgencyDetailAdvertisementPriceInfoPriceDtoUnitEnum.Currency]: EPriceUnit.CURRENCY,
    [RealEstateAgencyDetailAdvertisementPriceInfoPriceDtoUnitEnum.CurrencyPerM2]: EPriceUnit.CURRENCY_PER_M2,
    [RealEstateAgencyDetailAdvertisementPriceInfoPriceDtoUnitEnum.CurrencyPerMonth]: EPriceUnit.CURRENCY_PER_MONTH,
    [RealEstateAgencyDetailAdvertisementPriceInfoPriceDtoUnitEnum.CurrencyPerYear]: EPriceUnit.CURRENCY_PER_YEAR,
    [RealEstateAgencyDetailAdvertisementPriceInfoPriceDtoUnitEnum.CurrencyPerM2PerMonth]: EPriceUnit.CURRENCY_PER_M2_PER_MONTH,
    [RealEstateAgencyDetailAdvertisementPriceInfoPriceDtoUnitEnum.CurrencyPerM2PerYear]: EPriceUnit.CURRENCY_PER_M2_PER_YEAR,
    [RealEstateAgencyDetailAdvertisementPriceInfoPriceDtoUnitEnum.CurrencyPerDay]: EPriceUnit.CURRENCY_PER_DAY,
};
